import React from 'react';
import {Paper, Grid, Typography, Button} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import {red} from "@material-ui/core/colors";

import {defaults as branding} from './shared/BrandingContext'

const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        justifyContent: 'center'
    },

    content: {
        // border: 'thin solid',
        border: 'thin solid #dadce0',
        borderRadius: '3px',
        padding: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    tstatSelection: {
        marginTop: 50,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-around'
    },
    honeywellBtn: {
        color: red[500],
        borderColor: red[500]
    },
    emersonBtn: {
        color: '#004685',
        borderColor: '#004685',
    },
    sessionExpired: {
        padding: theme.spacing(2),
        backgroundColor: '#e7e98e',
        marginBottom: theme.spacing(2),
        maxWidth: 700
    }
});

const Home = withStyles(styles)(({history, location, classes}) => {
    const handleHoneywellClick = () => {
        history.push("/honeywell");
    };

    const handleHoneywellTccClick = () => {
        history.push("/honeywell-tcc");
    };

    const handleEmersonClick = () => {
        history.push('/emerson');
    };

    const isExpiredSession = location.pathname === "/expiredsession";


    return (
        <Grid container alignItems="center" className={classes.root} direction="column">
            <Paper elevation={0} className={classes.content}>

                {isExpiredSession && <Paper className={classes.sessionExpired}>Your session has been expired to protect your information. If you were directed here via an email, please click the link in the email to restart the process.</Paper>}

                <div><Typography variant="h5">Hello welcome to the Connected Energy Entergy Arkansas Enrollment Portal</Typography></div>
                <div><Typography variant="h6" color="textSecondary">Which manufacturer is your thermostat made by?</Typography></div>

                <div className={classes.tstatSelection}>
                    <Button variant="outlined" className={classes.honeywellBtn} size="large" onClick={handleHoneywellClick}>Honeywell</Button>
                    <Button variant="outlined" className={classes.honeywellBtn} size="large" onClick={handleHoneywellTccClick}>Honeywell TCC</Button>
                    <Button variant="outlined" className={classes.emersonBtn} size="large" onClick={handleEmersonClick}>Emerson</Button>
                </div>
            </Paper>
            <Typography variant="caption" style={{fontWeight: 100, fontSize: '0.50em', marginTop: 8}}>©2020 Connected Energy<sup>®</sup> (also known as BPL Global<sup>®</sup>LLC). All Rights Reserved.</Typography>

        </Grid>
    );
});

export default Home;