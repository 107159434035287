import React, {useContext} from 'react';
import {Grid, Typography} from "@material-ui/core";
import BrandingContext from "./BrandingContext";



const BrandingHeader =  ({...props}) => {
    const branding = useContext(BrandingContext);

    return (<>
        <Grid item style={{justifyContent: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <img src={branding.deviceLogo} width="250" />
        </Grid>

        <Grid item style={{textAlign: 'center'}}>
          <Typography variant="h6">Smart Direct Load Control Program Enrollment</Typography>
          <Typography variant="subtitle1">Bring your own qualifying device.</Typography>
        </Grid>
    </>);
};

export default BrandingHeader;