import Axios from 'axios/index'

const AUTH_HEADER = 'x-auth-token';

class Api {
    http = null;
    authToken = null;

    constructor(){
        this.http = Axios.create({
            baseURL: '/api',
            crossDomain: true
        });
    }



    set authToken(token){
        if(!this.http) return;

        if(token){
            this.http.defaults.headers['x-auth-token'] = token;
        } else {
            delete this.http.defaults.headers['x-auth-token'];
        }
    }

    oauthAuthUrl(clientRegistrationId){
        return `/oauth2/authorization/${clientRegistrationId}`;
    }

    /**
     * @typedef {Object} EnrollmentStatus
     * @property {number} customerId
     * @property {Array<String>} enrolledThermostatIds
     * @property {Array<String>} preenrolledThermostatIds
     */

    /**
     * @typedef {Object} AccountResult
     * @property {string} authToken - auth token for the session id
     * @property {EnrollmentStatus} enrollmentStatus
     */

    /**
     * Submits an account number to check for validity
     * @param {string} accountNum
     * @return {Promise<AccountResult>}
     */
    async submitAccountNumber(accountNum){
        const data = new FormData();
        data.set('accountNumber', accountNum);

        return this.http({
            method: 'post',
            url: '/enrollment/account',
            data,
            headers: {
                'Content-Type': `multipart/form-data`,
            }
        })
        .then(response => {
            const authToken = response.headers[AUTH_HEADER];

            return {authToken: authToken, enrollmentStatus: response.data};
        });
    }

    /**
     * @return {PreenrollmentResult}
     */
    processPreenrollment = async (vendor, request) => {
        const url = `/enrollment/${vendor}/enroll`;

        return this.http({
            method: 'post',
            url,
            data: request
        })
        .then(response => response.data);
    };

    /**
     * NOTE* the X-Auth-Token will be included as part of the request by default. See authToken field
     *
     * @returns {Promise<String>} the generated token which can be passed as "tkn" request param
     */
    generateToken = async () => {
        const url = '/enrollment/token';

        return this.http({
            method: 'post',
            url,
        })
        .then(response => {
            const {token} = response.data;
            return token;
        });
    };


    /**
     * @typedef {Object} PreenrollmentResult
     * @property {boolean} success
     * @property {String?} error
     * @property {Array<String>} tstats
     */

    ping() {
        return this.http.get('/ping');
    }
}

export default new Api();