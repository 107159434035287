import React from 'react'
import WizardContainer from "../shared/WizardContainer";
import BrandingContext, {defaults} from "../shared/BrandingContext";
import sensi_logo from './sensi-logo.png';
import Api from "../Api";

const context = {
    ...defaults,
    manufacturer: 'Emerson',
    deviceLogo: sensi_logo,
    oauthUrl: Api.oauthAuthUrl('emerson-user')
};

const EmersonWizard = (props) => {
    return (<BrandingContext.Provider value={context}>
        <WizardContainer />
    </BrandingContext.Provider>);
};

export default EmersonWizard;