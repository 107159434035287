import * as React from "react";
import WizardLayout from "./WizardLayout";
import Api from '../Api';
import EnrollmentContext from "../EnrollmentContext";
import STEP from './WizardState'
import {openWindow, padLeft, wait} from "./util";
import BrandingContext from "./BrandingContext";
import * as queryString from 'query-string'

const WIZARD_STATE_KEY = "wizardState";

const PING_INTERVAL = 1000 * 60 * 5;

const MIN_PREENROLLMENT_TIME = 2000;

const defaultState = {
    step: STEP.ACCOUNT_NUMBER,
    customer: null,
    homeInfo: null,

    checkingAccountNum: false,
    accountNumFailed: false,
    savingEnrollment: false,
    enrollmentError: null,
    pingInterval: null,

    popupBlocked: false,

    authToken: null,

    //if the customer is returning to the portal at a later date... This is just used to toggle a success message
    previouslyCompleted: false,
    enrolledTstats: [],
    preenrolledTstats: [],

    //oauth redirect window
    loginWindow: null
};

class WizardContainer extends React.Component {
    state = {...defaultState};

    static contextType = BrandingContext;

    static defaultProps = {
        OauthComponent: null
    };

    constructor(props) {
        super(props);

        const savedState = this.retrieveWizardState();
        if(savedState){
            Api.authToken = savedState.authToken;
            this.state = {
                ...this.state,
                ...savedState
            };
        }

        // :(
        window.testTcc = () => this.completePreenrollment("honeywell-tcc");
    }

    componentDidMount() {
        window.addEventListener('message', this.handleOauthLogin);
        this.startPingLoop();
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleOauthLogin);
        this.stopPingLoop();
    }

    handleAccountNumberSubmit = async (accountNum) => {
        accountNum = padLeft(accountNum, "000000000000");
        this.setState({checkingAccountNum: true, accountNumFailed: false});

        try {
            const {authToken, enrollmentStatus} = await Api.submitAccountNumber(accountNum);
            const customer = {
                id: enrollmentStatus.customerId,
                accountNum
            };

            let nextStep = STEP.PROGRAM_ELIGIBILITY;

            if(enrollmentStatus.enrolledThermostatIds.length > 0 || enrollmentStatus.preenrolledThermostatIds.length){
                nextStep = STEP.WIZARD_COMPLETE;
            }

            /* eslint-disable */
            Api.authToken = authToken;
            /* eslint-enable */

            await this.setStateAndPersist({
                customer,
                checkingAccountNum: false,
                accountNumFailed: false,
                step: nextStep,
                enrolledTstats: enrollmentStatus.enrolledThermostatIds,
                preenrolledTstats: enrollmentStatus.preenrolledThermostatIds,
                previouslyCompleted: true,
                authToken
            });

        } catch (e){
            console.log("ERROR!!!!", e);
            this.setState({checkingAccountNum: false, accountNumFailed: true});
        }
    };


    async showOauthWindow(){
        const handle = openWindow({
            url: 'about:blank',
            title: 'Thermostat Login',
            w: 800,
            h: 600
        });

        if(handle){
            let sessionToken = await Api.generateToken();
            const url = `${this.context.oauthUrl}?tkn=${sessionToken}`;
            handle.location = url;
        }

        const popupBlocked = !handle;
        this.setState({popupBlocked});
    }

    handleOauthLogin = async ({data, ...messageEvent}) => {
        switch(data){
            case "EMERSON_LOGIN_SUCCESS": {
                this.completePreenrollment("emerson");
                break;
            }
            case "HONEYWELL_LOGIN_SUCCESS": {
                this.completePreenrollment("honeywell");
                break;
            }
            case "TCC_LOGIN_SUCCESS": {
                this.completePreenrollment("honeywell-tcc");
                break;
            }
            default: return;
        }
    };


    /**
     * This will keep our session alive while the user is filling out the form. When the wizard completes or cancelled,
     * we should stop pinging and let the session die a happy death.
     */
    startPingLoop = () => {
        const handle = setInterval(Api.ping.bind(Api), PING_INTERVAL);
        this.setState(({pingInterval: handle}));
    };

    stopPingLoop = () => {
        const handle = this.state.pingInterval;
        if(handle){
            clearInterval(handle);
        }
    };

    setStateAndPersist = async (state) => {
        return new Promise(r => {
            this.setState(state, () => {
                this.persistWizardState();
                r();
            });
        })
    };

    /**
     * User has completed the form and is attempting to login to the thermostat providers oauth login.
     *
     * We need to generate a one time use token so that we can resolve the session on the server side using the window url
     * because we cannot pass a header as part of window.open.
     *
     * We need to save the customer info and then start the oauth process. When we receive the oauth callback we will
     * disable the form and show the loading and then process the preenrollment.
     */
    handleCustomerInfoSubmit = async ({details, address, homeInfo}) => {
        let customer = {...this.state.customer, details, address};


        await this.setStateAndPersist({customer, homeInfo});
        this.showOauthWindow();
    };

    handleCancel = () => {
        this.setStateAndPersist({...defaultState});
    };

    /**
     * User has received an error page and clicked back. The only step this can occur from is the form because that's
     * where the oauth sign in takes place as well for some reason
     */
    handleErrorBack = () => {
        this.setStateAndPersist({step: STEP.PROGRAM_ELIGIBILITY});
    };

    handleCustomerChange = async (customer) => {
        return new Promise(r => {
            this.setState({customer}, r);
        });
    };

    completePreenrollment = async (vendor) => {
        try {
            const {customer, homeInfo} = this.state;
            this.setState({savingEnrollment: true});

            let processStart = new Date().getTime();
            let result = await Api.processPreenrollment(vendor, {customer, homeInfo});
            let processFinish = new Date().getTime();
            let duration = processFinish - processStart;

            //we will show the "processing preenrollment" overlay for a minimum of 1 second even if we process it faster.
            if(duration < MIN_PREENROLLMENT_TIME){
                await wait(MIN_PREENROLLMENT_TIME - duration);
            }

            let tstatIds = (result.tstats || []).map(tstat => tstat.name ? `${tstat.name}: ${tstat.deviceId}` : tstat.deviceId);
            await this.setStateAndPersist({step: STEP.WIZARD_COMPLETE, savingEnrollment: false, previouslyCompleted: false, preenrolledTstats: tstatIds, loginWindow: null});
        } catch (error) {
            console.log(error);
            const response = this.extractResponseFromError(error);
            this.setStateAndPersist({step: STEP.WIZARD_ERROR, savingEnrollment: false, enrollmentError: response.error, loginWindow: null});
        }
    };

    retrieveWizardState(){
        const jsonState = sessionStorage.getItem(WIZARD_STATE_KEY);
        return !jsonState ? null : JSON.parse(jsonState);
    }

    /**
     * Saves necessary wizard state to session storage
     */
    persistWizardState(){
        const relevantState = {
            step: this.state.step,
            customer: this.state.customer,
            enrollmentError: this.state.enrollmentError,
            previouslyCompleted: this.state.previouslyCompleted,
            enrolledTstats: this.state.enrolledTstats,
            preenrolledTstats: this.state.preenrolledTstats,
            homeInfo: this.state.homeInfo,
            authToken: this.state.authToken
        };

        sessionStorage.setItem(WIZARD_STATE_KEY, JSON.stringify(relevantState));
    }

    getContext(){
        return {
            customer: this.state.customer,
            setCustomer: this.handleCustomerChange
        };
    }

    extractResponseFromError = (response) => {
        if(response.response && response.response.data){
            return response.response.data;
        }
        return {
            success: false,
            error: "An unknown error has occurred. Please try again later.",
            tstats: []
        };
    };

    render(){
        return (
            <EnrollmentContext.Provider value={this.getContext()}>
                <WizardLayout
                    step={this.state.step}
                    checkingAccountNum={this.state.checkingAccountNum}
                    onAccountNumberSubmit={this.handleAccountNumberSubmit}
                    accountNumFailed={this.state.accountNumFailed}
                    onCustomerInfoSubmit={this.handleCustomerInfoSubmit}
                    savingEnrollment={this.state.savingEnrollment}
                    enrollmentError={this.state.enrollmentError}
                    OauthComponent={this.props.OauthComponent}
                    previouslyCompleted={this.state.previouslyCompleted}
                    onCancel={this.handleCancel}
                    homeInfo={this.state.homeInfo}
                    enrolledTstats={this.state.enrolledTstats}
                    preenrolledTstats={this.state.preenrolledTstats}
                    popupBlocked={this.state.popupBlocked}
                    onErrorBack={this.handleErrorBack}
                />
            </EnrollmentContext.Provider>
        );
    }
}

export default WizardContainer;