import React from 'react'
import WizardContainer from "../shared/WizardContainer";
import BrandingContext, {defaults} from "../shared/BrandingContext";
import hw_logo from './hw_logo.png';
import Api from "../Api";

const context = {
    ...defaults,
    manufacturer: 'Honeywell',
    deviceLogo: hw_logo,
    oauthUrl: Api.oauthAuthUrl('honeywell-tcc')
};

const TccWizard = (props) => {
    return (<BrandingContext.Provider value={context}>
        <WizardContainer />
    </BrandingContext.Provider>);
};

export default TccWizard;