import React, {useContext, useState} from 'react'
import {withStyles} from "@material-ui/core/styles";
import {Button, Checkbox, Grid, Paper, Step, StepLabel, Stepper, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import * as PropTypes from 'prop-types';
import AccountNumberField from "../AccountNumberField";
import LoadingButton from "../LoadingButton";
import CustomerInfoForm from "./CustomerInfoForm";
import {red} from "@material-ui/core/colors";
import EnrollmentContext from "../EnrollmentContext";
import STEP from './WizardState'
import WizardComplete from "./WizardComplete";
import BrandingHeader from "./BrandingHeader";
import BrandingContext from "./BrandingContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";


const styles = (theme) => ({
    root: {
        minHeight: '100vh',
        backgroundColor: '#fbfbfb',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    container: {
        backgroundColor: '#fff',
        minHeight: 400,
        border: 'thin solid #dadce0',
        borderRadius: '3px',
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(2),
            width: 800
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minHeight: '100vh'
        }
    },
    content: {
        padding: theme.spacing(2),
        width: '100%',
        flexWrap: 'unset',
        overflow: 'hidden'
    },
    stepper: {
        backgroundColor: '#f3f3f3'
    }
});

const ACCOUNT_ERROR = "Please double check the information entered above, or call 833-807-7682 for assistance.";


const AccountScreen = ({onAccountNumberSubmit, accountNumFailed, loading}) => {
    const [accountNum, setAccountNum] = useState('');
    const [acceptsRules, setAcceptsRules] = useState(false);

    const submitAccountNumberDisabled = !acceptsRules || (accountNum.length === 0);

    const handleAccountNumChange = (event) => {
        setAccountNum(event.target.value);
    };

    const handleKeyDown = (event) => {
        if(event.keyCode === 13){
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        if(submitAccountNumberDisabled){
            return;
        }

        onAccountNumberSubmit(accountNum);
    };

    const handleAcceptsRulesChange = (event, val) => {
        setAcceptsRules(val);
    };


    return (
        <>
            <AccountNumberField autoFocus
                                onChange={handleAccountNumChange}
                                value={accountNum}
                                error={accountNumFailed && ACCOUNT_ERROR}
                                onKeyDown={handleKeyDown}
            />

            <div style={{textAlign: 'center'}}><a href="/downloads/termsandconditions.pdf" target="_blank">Terms and Conditions</a></div>

            <div style={{textAlign: 'center'}}><Checkbox checked={acceptsRules} onChange={handleAcceptsRulesChange} /> I understand and accept the program terms and conditions</div>


            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={submitAccountNumberDisabled}
                    loading={loading}
                    onClick={handleSubmit}
                    spinnerProps={{size: 28}}>
                    Next
                </LoadingButton>
            </div>
        </>
    );
};

const Layout = ({step, onAccountNumberSubmit, onCustomerInfoSubmit, onCancel, onErrorBack, checkingAccountNum, homeInfo, accountNumFailed, savingEnrollment, enrollmentError, previouslyCompleted, enrolledTstats, preenrolledTstats, popupBlocked, classes}) => {

    const context = useContext(EnrollmentContext);
    const brandingContext = useContext(BrandingContext);

    const {customer} = context;

    if(step === STEP.WIZARD_ERROR && !enrollmentError){
        enrollmentError = "An unknown error has occurred. Please try again later.";
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.container} elevation={0}>

                <Stepper activeStep={step} className={classes.stepper}>
                    <Step>
                        <StepLabel>Verify Entergy Arkansas Account</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Entergy Solutions Program Verification</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{brandingContext.manufacturer} Agreement</StepLabel>
                    </Step>
                </Stepper>

                <Divider/>

                <Grid container direction="column" alignItems="stretch" spacing={0} className={classes.content}>
                    <BrandingHeader />

                    <Backdrop open={savingEnrollment} style={{zIndex: 999999, alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column'}}>
                        <div><Typography variant="h4" style={{color: '#fff', marginTop: 16}}>Confirming Preenrollment</Typography></div>
                        <div><CircularProgress color="primary" style={{marginBottom: 32}}/></div>
                    </Backdrop>

                    {step === STEP.ACCOUNT_NUMBER && (() => {
                        return <Grid item>
                            <AccountScreen onAccountNumberSubmit={onAccountNumberSubmit} accountNumFailed={accountNumFailed} loading={checkingAccountNum}/>
                        </Grid>
                    }).apply()}

                    {step === STEP.PROGRAM_ELIGIBILITY && (() => {
                        return <Grid item>
                            {customer &&
                            <CustomerInfoForm
                                onSubmit={onCustomerInfoSubmit}
                                accountNum={context.customer.accountNum}
                                saving={savingEnrollment}
                                popupBlocked={popupBlocked}
                                customer={customer}
                                homeInfo={homeInfo}
                                onCancel={onCancel}
                            />}
                        </Grid>
                    }).apply()}

                    {step === STEP.WIZARD_ERROR && (() => {
                        return <Grid item style={{flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 150, flexDirection: 'column'}}>
                            <Typography style={{color: red[500], textAlign: 'center', marginTop: 16, width: '100%'}} variant="h5">{enrollmentError}</Typography>
                            <div style={{textAlign: 'right', marginTop: 16}}>
                                <Button variant="outlined" style={{marginRight: 8}} onClick={onCancel}>Cancel</Button>
                                <Button variant="contained" onClick={onErrorBack}>Back</Button>
                            </div>
                        </Grid>
                    }).apply()}

                    {step === STEP.WIZARD_COMPLETE && (() => {
                        return <WizardComplete
                            enrolledTstats={enrolledTstats}
                            preenrolledTstats={preenrolledTstats}
                            previouslyCompleted={previouslyCompleted}
                        />
                    }).apply()}

                </Grid>
            </Paper>

            <div>
                <Typography variant="caption" style={{fontWeight: 100, fontSize: '0.50em', textAlign: 'center'}} component="p">©2020 Connected Energy<sup>®</sup> (also known as BPL Global<sup>®</sup>LLC). All Rights Reserved.</Typography>
            </div>

        </div>
    );
};

Layout.propTypes = {
    step: PropTypes.oneOf(Object.values(STEP)),
    homeInfo: PropTypes.object,
    onAccountNumberSubmit: PropTypes.func.isRequired,
    checkingAccountNum: PropTypes.bool,
    accountNumFailed: PropTypes.bool,
    onCustomerInfoSubmit: PropTypes.func.isRequired,
    savingEnrollment: PropTypes.bool.isRequired,
    enrollmentError: PropTypes.string,
    enrolledTstats: PropTypes.array.isRequired,
    preenrolledTstats: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    onErrorBack: PropTypes.func.isRequired,
    previouslyCompleted: PropTypes.bool.isRequired
};

export default withStyles(styles)(Layout);