import {detect} from 'detect-browser'

export const padLeft = (str, padding) => {
    return String(padding + str).slice(-padding.length);
};


export const wait = async (delayMillis) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, delayMillis);
    });
};


export const openWindow = ({url, title, w, h}) => {
    const newWindow = window.open(url, title, `scrollbars=yes,width=${w},height=${h}`);

    if (window.focus && newWindow) newWindow.focus();

    return newWindow;
};

/**
 * @returns {boolean} true if browser is safari :)
 */
export const isSafari = () => {
    try {
        const browser = detect();
        console.log(`Browser = ${browser.name}`);
        return browser.name === 'safari' || browser.name === 'ios';

    } catch (error){
        return false;
    }
};
