import React from 'react'
import {Grid, Typography, ListSubheader, List, ListItem, ListItemText} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import withStyles from "@material-ui/core/styles/withStyles";


const styles = theme => ({
    li: {
        border: 'thin solid #dadada',
        backgroundColor: '#f3f3f3',
        marginBottom: theme.spacing(0.5)
    }
});

export default withStyles(styles)(({enrolledTstats, preenrolledTstats, previouslyCompleted = false, classes}) => {
    enrolledTstats = enrolledTstats || [];
    preenrolledTstats = preenrolledTstats || [];

    return (
        <Grid item style={{display: 'flex', padding: 16, flexDirection: 'column'}}>
            <Typography variant="h4" style={{textAlign: 'center'}} color="primary">Thanks for completing the enrollment process</Typography>
            {!previouslyCompleted && <Typography style={{textAlign: 'center', marginTop: 16}}>Your enrollment status is currently pending and you will receive an email in 2-5 business days when your enrollment is confirmed.</Typography>}

            <List dense disablePadding>
                {enrolledTstats.length > 0 && <>
                    <ListSubheader disableGutters>Your enrolled Thermostats</ListSubheader>
                    {enrolledTstats.map(tstatId => (<ListItem key={tstatId} classes={{root: classes.li}}>
                        <ListItemText>{tstatId}</ListItemText>
                    </ListItem>))}
                </>}

                {preenrolledTstats.length > 0 && <>
                    <ListSubheader disableGutters>Preenrolled Thermostats (awaiting approval)</ListSubheader>
                    {preenrolledTstats.map(tstatId => (<ListItem key={tstatId} classes={{root: classes.li}}>
                        <ListItemText>{tstatId}</ListItemText>
                    </ListItem>))}
                </>}
            </List>
        </Grid>
    );
});