import {createContext} from "react";
import ce_logo from "./ce_logo.svg";
import hw_logo from "./hw_logo.png";
import entergy_logo from "./entergy_logo.png";

export const defaults = {
    manufacturer: null,
    deviceLogo: null,
    cnrgLogo: ce_logo,
    entergyLogo: entergy_logo,
    oauthUrl: null
};

const BrandingContext = createContext(defaults);

export default BrandingContext;