export const STEP = {
    ACCOUNT_NUMBER: 0,
    PROGRAM_ELIGIBILITY: 1,
    WAITING_TSTAT_OAUTH: 2,
    WIZARD_COMPLETE: 3,
    WIZARD_ERROR: 4
};


export default STEP;
