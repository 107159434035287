import React, {useContext} from 'react'
import {Field, Formik} from "formik";
import Grid from "@material-ui/core/Grid";
import {Checkbox, CheckboxWithLabel, RadioGroup, TextField} from "formik-material-ui";

import * as yup from 'yup';
import LoadingButton from "../LoadingButton";
import {Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import PhoneNumberField from "./PhoneNumberField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import states from './States';
import BrandingContext from "./BrandingContext";
import InputAdornment from "@material-ui/core/InputAdornment";
import {red} from "@material-ui/core/colors";

const HEAT_SOURCES = ['Gas', 'Furnace', 'Heat Pump', 'BB Resistance', 'Boiler', 'Other', 'Unknown'];
const COOLING_TYPES = ['Central AC', 'HP', 'Window AC', 'ES Window', 'Fans Only', 'N/A'];

const formShape = {
    details: {
        first: '',
        last: '',
        phone: '',
        email: '',
    },
    address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: ''
    },
    homeInfo: {
        squareFt: '',
        acTonnage: '',
        centralAir: true,
        heatType: '',
        homeOwner: true
    }
};

const formValidation = yup.object({
    details: yup.object().shape({
        first: yup.string().label('First name').required().min(2).max(16),
        last: yup.string().label("Last name").required().min(2).max(16),
        phone: yup.string().label("Phone number").required().matches(/[0-9]{3}\.[0-9]{3}\.[0-9]{4}/, 'Phone number must be in the format xxx.xxx.xxxx'),
        email: yup.string().label("Email Address").required().email(),
    }),
    address: yup.object().shape({
        address1: yup.string().label("Address line 1").required().max(64),
        address2: yup.string().label("Address line 2").max(64),
        city: yup.string().label("City").required().max(64),
        state: yup.string().label("State").required().max(2),
        zip: yup.string().label("Zip Code").required().max(5)
    }),
    homeInfo: yup.object().shape({
        squareFt: yup.number().label("Square footage").required().typeError("Square footage must be a number"),
        acTonnage: yup.number().label("A/C Tonnage").typeError("A/C Tonnage must be a number"),
        age: yup.number().label("Home Age").typeError("Home Age must be a number").required(),
        centralAir: yup.boolean(),
        heatType: yup.string().label("Heat Type").oneOf(HEAT_SOURCES).required(),
        homeOwner: yup.boolean(),
        coolType: yup.string().label("Cool Type").oneOf(COOLING_TYPES).required(),
    })
});

const CustomerInfoForm = ({onSubmit, onCancel, saving, customer, homeInfo, accountNum, popupBlocked}) => {
    const formValues = {...formShape};


    if(customer.details){
        formValues.details = customer.details;
    }
    if(customer.address){
        formValues.address = customer.address;
    }
    if(homeInfo){
        formValues.homeInfo = homeInfo;
    }

    return <Grid container spacing={1}>
        <Formik
            onSubmit={onSubmit}
            initialValues={formValues}
            validateOnMount
            validationSchema={formValidation}
        >
            {(formikProps) => <Form accountNum={accountNum} saving={saving} formikProps={formikProps} onCancel={onCancel} popupBlocked={popupBlocked} />}
        </Formik>
    </Grid>
};

const FormHeader = ({text}) => (<Grid item xs={12} style={{marginTop: 24}}>
    <Typography variant="h6">{text}</Typography>
</Grid>);

const FormField = ({width, ...fieldProps}) => (
    <Grid item xs={width}>
        <Field variant="filled" {...fieldProps} />
    </Grid>
);


const heatSourceStyle = {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    padding: 16,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3
};

const Form = ({saving, accountNum, onCancel, popupBlocked, formikProps}) => {
    const context = useContext(BrandingContext);

    const common = {
        component: TextField,
        disabled: saving,
        fullWidth: true,
        onKeyDown: (e) => {
            if(e.keyCode === 13){
                formikProps.submitForm();
            }
        },
    };

    const popupWarningColor = popupBlocked ? red[500] : undefined;
    const popupWarningVariant = popupBlocked ? "h6" : 'subtitle2';

    return <>
        <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Account Number: </strong> {accountNum}</Typography>
            <Typography variant="caption">* fields are required</Typography>
        </Grid>

        <FormHeader text="Contact Information" />
        <FormField width={6} {...common} name="details.first" label="First name" autoFocus required/>
        <FormField width={6} {...common} name="details.last" label="Last name"  required/>
        <FormField width={12} {...common} name="details.phone" label="Phone Number" component={PhoneNumberField} required/>
        <FormField width={12} {...common} name="details.email" label="Email Address" required />

        <FormHeader text="Address" />
        <FormField width={12} {...common} name="address.address1" label="Street" required />
        <FormField width={12} {...common} name="address.address2" label="Line 2" />
        <FormField width={12} {...common} name="address.city" label="City" required/>

        <FormField
            width={6}
            component={TextField}
            fullWidth
            name="address.state"
            label="State"
            required
            select
            InputLabelProps={{shrink: true}}
        >
            {states.map(({name, abbreviation}) => (
                <MenuItem key={abbreviation} value={abbreviation}>{name}</MenuItem>
            ))}
        </FormField>

        <FormField width={6} {...common} name="address.zip" label="ZIP Code" required/>

        <FormHeader text="Home Information" />
        <FormField width={6} {...common} name="homeInfo.squareFt" label="Home Square Footage" required />
        <FormField width={6} {...common} name="homeInfo.acTonnage" label="A/C Tonnage" />
        <FormField width={12} {...common} name="homeInfo.age" required InputProps={{endAdornment: <InputAdornment position="end">Years</InputAdornment>}} label="Age of home" />

        <FormField width={12} name="homeInfo.heatType" label="Heat Type" component={RadioGroup} style={heatSourceStyle}>
            <Typography style={{padding: 4, paddingBottom: 0, marginTop: 8}} variant="subtitle2">Heat source for the home <strong style={{color: 'red'}}>*</strong> </Typography>
            {HEAT_SOURCES.map(type => {
                const label = type === 'Unknown' ? "I'm not sure" : type;
                return (
                    <FormControlLabel
                        key={type}
                        value={type}
                        label={label}
                        control={<Radio />}
                    />
                );
            })}
        </FormField>

        <FormField width={12} name="homeInfo.coolType" label="Cool Type" component={RadioGroup} style={heatSourceStyle}>
            <Typography style={{padding: 4, paddingBottom: 0, marginTop: 8}} variant="subtitle2">Cooling source for the home <strong style={{color: 'red'}}>*</strong> </Typography>
            {COOLING_TYPES.map(type => {
                const label = type === 'Unknown' ? "I'm not sure" : type;
                return (
                    <FormControlLabel
                        key={type}
                        value={type}
                        label={label}
                        control={<Radio />}
                    />
                );
            })}
        </FormField>

        <FormField width={12} name="homeInfo.centralAir" Label={{label:"Does your home have central air?"}} component={CheckboxWithLabel}/>
        <FormField width={12} name="homeInfo.homeOwner" Label={{label:"Do you own your home?"}} component={CheckboxWithLabel}/>

        <Grid item xs={12}>
            <Typography style={{marginTop: 16, marginBottom: 16}} variant="subtitle2">
                The next step will open up a popup to the thermostat manufacturer&apos;s account login. After logging into your {context.manufacturer} account and granting access to your thermostats, you will be redirected back to this portal.
            </Typography>
            <Typography style={{textDecoration: 'underline', color: popupWarningColor}} variant={popupWarningVariant}>
                If nothing happens after pressing the button, make sure popups are not being blocked by your browser.
            </Typography>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'right'}}>
            <Button variant="contained" onClick={onCancel} style={{marginRight:8}}>Cancel</Button>

            <LoadingButton
                variant="contained"
                color="primary"
                loading={saving}
                onClick={formikProps.handleSubmit}
                disabled={!formikProps.isValid}
                spinnerProps={{size: 28}}>
                Login to {context.manufacturer}
            </LoadingButton>
        </Grid>
    </>
};


export default CustomerInfoForm;