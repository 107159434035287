import React from 'react'
import MaskedInput from "react-text-mask";
import {TextField} from "formik-material-ui";

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            guide={false}
            showMask
        />
    );
}

const PhoneNumberField = ({error, ...props}) => {
    const hasError = Boolean(error);
    return (
        <TextField
            variant="filled"
            label="Phone Number"
            placeholder="xxx.xxxx.xxxx"
            InputLabelProps={{shrink: true}}
            fullWidth
            error={hasError}
            helperText={hasError && error}
            InputProps={{inputComponent: TextMaskCustom}}

            {...props}
        />
    );
};
export default PhoneNumberField;