import React from 'react';

import * as PropTypes from 'prop-types';
import {CircularProgress, Button} from "@material-ui/core";

const LoadingButton = ({loading, spinnerProps, children, ...props}) => {
    const style = {borderRadius: 0};
    if(loading){
        return <Button {...props} disabled={loading} style={style}><CircularProgress {...spinnerProps} color="secondary"/></Button>;
    } else {
        return <Button {...props} style={style}>{children}</Button>;
    }
};

LoadingButton.propTypes = {
    loading: PropTypes.bool,
    spinnerProps: PropTypes.object
};

export default LoadingButton;