import React, {useState} from 'react'
import * as PropTypes from 'prop-types'
import {TextField} from "@material-ui/core";
import MaskedInput from "react-text-mask";


const AccountNumberField = ({error, ...props}) => {
    const hasError = Boolean(error);
    return (
        <TextField
            variant="outlined"
            label="Entergy Arkansas Account Number"
            InputLabelProps={{shrink: true}}
            placeholder="xxxxxxxxxxx"
            margin="normal"
            fullWidth
            error={hasError}
            helperText={hasError && error}

            {...props}
        />
    );
};

export default AccountNumberField;