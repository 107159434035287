import React from 'react';
import './App.css';
import TccWizard from "./tcc/TccWizard";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Home from "./Home";
import EmersonWizard from "./emerson/EmersonWizard";
import HoneywellWizard from "./honeywell/TccWizard";
import {isSafari} from "./shared/util";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#E71D35'
    },
    secondary: {
        main: '#449443'
    }
  }
});

const SafariWarning = (props) => (<div style={{width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
    <h5>This portal currently does not work in the Safari web browser. Please use a different browser.</h5>
    <h5>We apologize for the inconvenience.</h5>
</div>);

function App() {
    return (
      <ThemeProvider theme={theme}>
          <BrowserRouter>
              <Switch>
                <Route path="/honeywell-tcc" component={TccWizard} />
                <Route path="/honeywell" component={HoneywellWizard} />
                <Route path="/emerson" component={EmersonWizard} />
                <Route path="*" component={Home} />
              </Switch>
          </BrowserRouter>
      </ThemeProvider>
    );
}

export default App;
